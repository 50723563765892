import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Ant from "antd";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { navigate } from "gatsby";

import { Font, Color } from "../Components/Widget";
import TeamList from "../Components/TeamList";
import SearchInput from "../Components/SearchInput";

import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { withPage } from "../PageContainer";
import * as Survey from "../Contexts/SurveyContext";

/**
 * 團隊 Modal
 * @param {visible} bool 是否呈現
 * @param {profile} obj 登入用戶物件
 * @param {onCancel} func 取消 callback
 * @param {selectTeam} obj 預設團隊物件
 * @param {userList} array 用戶列表，目前沒用
 * @param {userCode} string 用戶代碼
 * @return {CreateTeamModal}
 */
function CreateTeamModal({
  visible,
  profile,
  onCancel,
  userCode,
  selectTeam,
  ...props
}) {
  const [teamid, setTeamId] = useState(""); // 選擇的team id
  const [name, setName] = useState(""); // 編輯團隊名稱

  useEffect(() => {
    setTeamId(selectTeam ? selectTeam.teamid : "");
    setName(selectTeam ? selectTeam.name : "");
  }, [selectTeam]);

  /**
   * 送出團隊名稱 Submit
   * @returns
   */
  async function submitTeam() {
    if (name.length > 50) {
      alert("團隊名稱最大長度為50");
      return;
    }

    try {
      let data = {
        teamId: teamid,
        userCode: userCode,
        name: name,
      };
      let resJSON = await Survey.Actions.submitTeam(data);
      if (resJSON.code && resJSON.code === 200) {
        navigate("/team?id=" + resJSON.data + "&t=1");
      } else {
        alert(resJSON.message);
      }
    } catch (err) {}
  }

  return (
    <>
      <Ant.Modal
        className="custom-modal"
        title={selectTeam ? "團隊內容 - " + selectTeam.name : "新增團隊"}
        width={480}
        visible={visible}
        onCancel={onCancel}
        okText={"確定"}
        onOk={async () => {
          await submitTeam();
        }}
        cancelText={"取消"}
      >
        <div>
          <Ant.Form layout="vertical">
            <div>
              <Ant.Input
                placeholder="輸入團隊名稱"
                value={name ? name : ""}
                name="name"
                onChange={e => {
                  setName(e.target.value);
                }}
              />
            </div>
          </Ant.Form>
        </div>
      </Ant.Modal>
    </>
  );
}

/**
 * 團隊列表
 * @param {profile} obj 登入用戶物件
 * @return {CreateTeTeamListPageamModal}
 */
function TeamListPage({ profile, ...props }) {
  const { appActions } = props;
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectTeam, setSelectTeam] = useState(null);
  const [userCode, setUserCode] = useState(null);
  const [searchName, setSearchName] = useState("");

  let windowGlobal = typeof window !== "undefined" && window;

  useEffect(() => {
    windowGlobal = typeof window !== "undefined" && window;
    let token = windowGlobal.localStorage.getItem("token");
    setUserCode(profile.UserCode);

    /**
     * 取得團隊列表
     */
    async function fetchData() {
      setLoading(true);
      try {
        setTeams(await Survey.Actions.fetchTeamList(profile));
      } catch (ex) {
        // alert("系統異常，請洽系統管理員");
      }
      setLoading(false);
    }
    // 是否有權限存取團隊列表
    if (profile && token && profile && profile.RoleId < 2) {
      fetchData();
    }

    // 錯誤登出用戶轉導首頁
    if (!profile || !token) {
      appActions.logout();
      console.debug("各路工程師想要使用者回老家");
      navigate("/");
    } else if (profile && profile.RoleId > 1) {
      console.debug("各路工程師想要使用者回老家");
      navigate("/");
    }
  }, [profile]);

  /**
   * 刪除團隊
   * @param {team} obj 團隊物件
   */
  async function removeTeam(team) {
    Ant.Modal.confirm({
      title: "確認要刪除？",
      content: "",
      okText: "取消",
      okType: "primary",
      onOk: () => 0,
      cancelButtonProps: {
        type: "ghost",
        style: { border: `1px solid ${Color.Purple}`, color: Color.Purple },
      },
      cancelText: "確認刪除",
      onCancel: async () => {
        const hide = Ant.message.loading("刪除中...", 0);
        try {
          await Survey.Actions.deleteTeam(team.teamid);
          props.setUpdater(props.updater + 1);
        } catch (ex) {
          // console.warn(ex);
        }
        hide();
        window.location.replace("/");
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  return (
    <Wrapper>
      <div className="content">
        <Font.LargeTitle style={{ marginBottom: 34 }}>
          團隊管理列表
        </Font.LargeTitle>
        <Ant.Row justify="space-between" style={{ marginBottom: 38 }}>
          <Ant.Button
            type="primary"
            icon={<PlusOutlined size={14} />}
            style={{ height: 40, minWidth: 120 }}
            onClick={() => {
              setShowCreateModal(true);
              setSelectTeam(null);
            }}
          >
            新增團隊
          </Ant.Button>
          {/* <Ant.Input.Search
            placeholder="團隊名稱、姓名、帳號..."
            onSearch={value => {
              setSearchName(value);
            }}
            style={{
              width: 320,
              float: "right",
            }}
          /> */}
          <SearchInput
            width={270}
            placeholder="團隊名稱、姓名、帳號..."
            onChange={e => setSearchName(e.target.value)}
          />
        </Ant.Row>
        {profile && profile.RoleId < 2 && (
          <TeamList
            teams={teams}
            loading={loading}
            searchName={searchName}
            openEditModal={team => {
              setShowCreateModal(true);
              setSelectTeam(team);
            }}
            removeTeam={team => {
              removeTeam(team);
            }}
          />
        )}
        <CreateTeamModal
          visible={showCreateModal}
          profile={profile}
          selectTeam={selectTeam}
          userCode={userCode}
          onCancel={() => {
            setShowCreateModal(false);
            setSelectTeam(null);
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fafafa;
  min-height: calc(100vh - 64px);

  & > .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 32px 36px 0;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(TeamListPage)
);
